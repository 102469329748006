import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types/types";

interface AppState {
  users: User[];
  editingUser?: User;
}

// Define the initial state using that type
const initialState: AppState = {
  users: [],
  editingUser: undefined,
};
export const AppSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    addUser: (state, action: PayloadAction<User>) => {
      state.users.push(action.payload);
    },
    removeUser: (state, action: PayloadAction<{ userUID: string }>) => {
      state.users = state.users.filter((user) => user.id !== action.payload.userUID);
    },
    setEditingUser: (state, action: PayloadAction<User | undefined>) => {
      state.editingUser = action.payload;
    },
  },
});

export const { addUser, removeUser, setUsers, setEditingUser } = AppSlice.actions;

export default AppSlice.reducer;
