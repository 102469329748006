import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tournament } from "../../types/types";

interface NotificationState {
  tournament: Tournament;
  editingTournament?: Tournament;
}

const tournamentSlice = createSlice({
  name: "tournament",
  initialState: {} as NotificationState,
  reducers: {
    setTournament: (state: NotificationState, action: PayloadAction<Tournament>) => {
      state.tournament = action.payload;
    },
    setEditingTournament: (state: NotificationState, action: PayloadAction<Tournament>) => {
      state.editingTournament = action.payload;
    },
  },
});

export const { setTournament, setEditingTournament } = tournamentSlice.actions;

export default tournamentSlice.reducer;
