import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppDispatch } from "../../../hooks";
import Button from "@mui/material/Button";
import { getTournaments } from "../../../api/api";
import { setEditingLocation } from "../../../features/locations/locationsSlice";
import useConfirm from "../../../hooks/useConfirm";
import { Card, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { SPORT_TYPE, Tournament } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { formatDate } from "../../../utils";
import moment from "moment";
import { TableCellIconStyles } from "../../../styles/theme";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { setEditingTournament } from "../../../features/tournament/tournamentSlice";
import CheckIcon from "@mui/icons-material/Check";

export default function Tournaments() {
  const dispatch = useAppDispatch();
  const [Dialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil slette denne turnering?");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);

  useEffect(() => {
    dispatch(setEditingLocation(undefined));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getTournaments()
      .then((data: any) => {
        setTournaments(data.sort((a: any, b: any) => b.date - a.date));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const editTournamentHandler = (tournament: Tournament) => {
    dispatch(setEditingTournament(tournament));
    navigate(ROUTES.SUPER_ADMIN_CREATE_TOURNAMENT);
  };

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Turneringer</Typography>
            <div>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => navigate(ROUTES.SUPER_ADMIN_CREATE_TOURNAMENT)}
              >
                Opret
              </Button>
            </div>
          </Stack>
          <Card sx={{ width: "100%" }}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Dato</TableCell>
                    <TableCell>Vis på forside</TableCell>
                    <TableCell>LIVE-label</TableCell>
                    <TableCell>Aktiv</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tournaments.map((tournament: Tournament) => {
                    return (
                      <TableRow
                        hover
                        key={tournament.id}
                        sx={{
                          opacity: tournament.date > moment().unix() ? 1 : 0.25,
                          fontStyle: tournament.date > moment().unix() ? "normal" : "italic",
                        }}
                      >
                        <TableCell>
                          <Typography variant="subtitle2">{`${tournament.name} ${tournament.sportType === SPORT_TYPE.FOOTBALL ? '⚽️' : '🏐'}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{formatDate(tournament.date)}</Typography>
                        </TableCell>
                        <TableCell>{tournament.showInTournamentDates && <CheckIcon />}</TableCell>
                        <TableCell>{tournament.isGoingLive && <CheckIcon />}</TableCell>
                        <TableCell>{tournament.isActive && <CheckIcon />}</TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                        >
                          <ModeEditOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => editTournamentHandler(tournament)}
                          />
                          {/*
                           <DeleteOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => deleteLocationHandler(location.id, location.location)}
                          /> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
