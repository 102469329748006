import { useState } from "react";
import CreateKnckoutMatchDialog from "../../../components/update-knockout-match-dialog";
import KnockOutMatch from "../../../components/knockout-match";
import { KnockOutMatches } from "../../../features/matches/matchesSlice";
import { useAppSelector } from "../../../hooks";
import { KNOCK_OUT_MATCH_TYPE, Match } from "../../../types/types";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function RoundOf16Teams() {
  const pulje = useAppSelector((state) => state.puljer.selectedPulje)?.id;

  const [selectedKnockoutMatch, setSelectedKnockoutMatch] = useState<keyof KnockOutMatches>();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const matches = useAppSelector((state) => state.matches.groupMatches);
  const r16_1 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_1");
  const r16_2 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_2");
  const r16_3 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_3");
  const r16_4 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_4");
  const r16_5 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_5");
  const r16_6 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_6");
  const r16_7 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_7");
  const r16_8 = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "r16_8");
  const { isDesktop } = useWindowDimensions();

  const knockOutMatchClickedHandler = (match: Match) => {
    setSelectedMatch(match);
    setSelectedKnockoutMatch(match.knockoutMatchId as any);
    setOpenDialog(true);
  };

  const closeDialogHandler = () => {
    setSelectedKnockoutMatch(undefined);
    setSelectedMatch(undefined);
    setOpenDialog(false);
  };

  return (
    <>
      <CreateKnckoutMatchDialog
        open={openDialog}
        handleClose={closeDialogHandler}
        selectedMatch={selectedMatch}
        selectedKnockoutMatchKey={selectedKnockoutMatch}
        knockOutMatchType={KNOCK_OUT_MATCH_TYPE.ROUND_OF_16}
      />
      <div className="knockout-teams">
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_1} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_2} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_3} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_4} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_5} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_6} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_7} />
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={r16_8} />
      </div>
    </>
  );
}

export default RoundOf16Teams;
