import locationReducer from "./features/locations/locationsSlice";
import puljeReducer from "./features/puljer/PuljeSlice";
import teamsReducer from "./features/teams/TeamsSlice";
import groupsReducer from "./features/groups/GroupsSlice";
import matchesReducer from "./features/matches/matchesSlice";
import appReducer from "./features/app/appSlice";
import tournamentReducer from "./features/tournament/tournamentSlice";
import notificationReducer from "./features/notification/notificationSlice";
import usersReducer from "./features/users/usersSlice";
import clubsReducer from "./features/clubs/clubsSlice";
import settingsReducer from "./features/settings/settings";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    puljer: puljeReducer,
    teams: teamsReducer,
    groups: groupsReducer,
    matches: matchesReducer,
    app: appReducer,
    locations: locationReducer,
    notification: notificationReducer,
    users: usersReducer,
    tournament: tournamentReducer,
    clubs: clubsReducer,
    settings: settingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
