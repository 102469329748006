import { Match, SPORT_TYPE } from "../../types/types";
import { Nationality, Pot, ExtendedTeamType } from "./data";

export const getEmoji = (nationality: Nationality) => {
  switch (nationality) {
    case Nationality.DENMARK:
      return "🇩🇰";
    case Nationality.NORWAY:
      return "🇳🇴";
    case Nationality.ENGLAND:
      return "🏴󠁧󠁢󠁥󠁮󠁧󠁿";
    case Nationality.BELGIUM:
      return "🇧🇪";
    case Nationality.FRANCE:
      return "🇫🇷";
    case Nationality.NETHERLANDS:
      return "🇳🇱";
    case Nationality.SWITZERLAND:
      return "🇨🇭";
    default:
      "-";
  }
};

const randomGenerator = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const findOpponent = (myTeam: ExtendedTeamType, searchPot: Pot, existingMatches: Match[]) => {
  // Club filtering
  const filteredPot = searchPot.teams.filter((team) => team.club?.id !== myTeam.club?.id);

  // Pot filtering
  let validModstandere = filteredPot.filter(
    (team) => !team.potMatches.includes(searchPot.potId) && !team.potMatches.includes(myTeam.potId)
  );

  // Man kan max møde 2 hold fra samme land

  if (myTeam.opponents.filter((op) => op.nationality === myTeam.nationality).length === 2) {
    validModstandere = validModstandere.filter((team) => team.nationality !== myTeam.nationality);
  }

  const randomIndex = randomGenerator(validModstandere.length);

  const opponent = validModstandere[randomIndex];

  return opponent;
};

export const getSportType = () => {
  if (document.URL.includes("localhost")) return SPORT_TYPE.FOOTBALL;
  return document.URL.includes("tamil-football") ? SPORT_TYPE.FOOTBALL : SPORT_TYPE.VOLLEYBALL;
};
