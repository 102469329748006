import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useAppSelector } from "../../hooks";
import { GroupType, Match, MATCH_TYPE, STATUS } from "../../types/types";
import { getGoalDifference, getGoalsFor, getLogo, getPoints, sortTeams } from "../../utils";
import ballLogo from "../../assets/icons/ball-logo.png";
import { useMemo } from "react";

const Slideshow = () => {
  const groups = useAppSelector((state) => state.groups.groups);
  const groupMatches = useAppSelector((state) => state.matches.groupMatches).filter(
    (match) => match.matchType === MATCH_TYPE.GROUP_MATCH
  );

  const data = useMemo(() => {
    return groups.map((group) => {
      const teamIds = group.teams?.map((team) => team.id);
      return {
        group,
        matches: groupMatches.filter(
          (match) => teamIds?.includes(match.homeTeam?.id ?? '') || teamIds?.includes(match.awayTeam?.id ?? '')
        ),
      };
    });
  }, [groups, groupMatches]);

  return (
    <div style={{ height: "100vh", width: "90vw", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Zoom scale={1.4} duration={5000} arrows={false}>
        {data.map((group) => (
          <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
            <Table group={group.group} groupMatches={groupMatches} />
            <Matches matches={group.matches} />
          </div>
        ))}
      </Zoom>
    </div>
  );
};

const Table = (props: { group: GroupType, groupMatches: Match[] }) => {
  const { group, groupMatches } = props;
  return (
    <div className="slideshow-table">
      <div className="table-content">
        {
          <div className="row header">
            <div className="club-column">
              <p className="group-name">GROUP {group.name.toUpperCase()}</p>
            </div>
            <div className="points-column">
              <p className="letter">F</p>
              <p className="letter">GD</p>
              <p className="letter">PTS</p>
            </div>
          </div>
        }
      </div>
      <div className="table-content">
        {group.teams &&
          sortTeams(group.teams, groupMatches, true).map((team, index) => {
            return (
              <div key={team.id} className="row">
                <div className="club-column">
                  <p className="order-column">{index + 1}</p>
                  <img src={team.club?.logo ? team.club?.logo : ballLogo} alt="" height={14} />
                  <span className="team-name">{team.teamName}</span>
                </div>

                <div className="points-column">
                  <p className="number">{getGoalsFor(team.id, groupMatches)}</p>
                  <p className="number">{getGoalDifference(team.id, groupMatches)}</p>
                  <p className="number" style={{ color: "white" }}>
                    {getPoints(team.id, groupMatches)}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Matches = (props: { matches: Match[] }) => {
  const { matches } = props;
  return (
    <div className="slideshow-matches">
      {matches.map((match) => (
        <div className="slideshow-match">
          <div className={match.status === STATUS.PLAYING ? "column-0" : ""}></div>
          <div className="column-2">
            <div className="team">
              {match.homeTeam && getLogo(match.homeTeam)}

              {match.homeTeam ? (
                <span>{match.homeTeam.teamName}</span>
              ) : (
                <span style={{ fontStyle: "italic" }}>{match.homeTeamLabel ? match.homeTeamLabel : "Pending"}</span>
              )}
            </div>
            <div className="team">
              {match.awayTeam && getLogo(match.awayTeam)}

              {match.awayTeam ? (
                <span>{match.awayTeam?.teamName}</span>
              ) : (
                <span style={{ fontStyle: "italic" }}>{match.awayTeamLabel ? match.awayTeamLabel : "Pending"}</span>
              )}
            </div>
          </div>
          <div className="column-4">
            <div className="point">
              {match.status !== STATUS.PENDING && match.status !== STATUS.CANCELLED && match.homeTeamPoints}
            </div>
            <div className="point">
              {match.status !== STATUS.PENDING && match.status !== STATUS.CANCELLED && match.awayTeamPoints}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slideshow;
