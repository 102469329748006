import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setAdmin } from "../features/app/appSlice";
import { useNavigate } from "react-router-dom";
import NotificationService from "./notification/NotificationService";
import { LoadingButton } from "@mui/lab";
import {
  buttonStyles,
  dialogActionsStyles,
  dialogContentStyles,
  dialogStyles,
  textFieldStyles,
  textFieldInputLabelProps,
} from "../styles/theme";
import { signIn, signOut } from "../supabase";

interface Props {
  open: boolean;
  returnCallback: () => void;
}

export default function Login(props: Props) {
  const { open, returnCallback } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const admin = useAppSelector((state) => state.app.admin);
  const [errorInEmail, setErrorInEmail] = useState(false);
  const [errorInPassword, setErrorInPassword] = useState(false);

  const login = async () => {
    setLoading(true);

    try {
      const userId = await signIn(email, password);
      if (!userId) throw new Error();

      setEmail("");
      setPassword("");

      returnCallback();
    } catch (e) {
      NotificationService.showToast({
        text: "Invalid email or password",
        severity: "error",
        duration: 3000,
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    signOut();
    returnCallback();
  };

  return (
    <Dialog className="sign-in-modal" sx={dialogStyles} open={open} onClose={returnCallback}>
      <DialogTitle sx={{ ...dialogContentStyles, color: "white" }}>Authentication</DialogTitle>
      <DialogContent sx={{ ...dialogContentStyles, justifyContent: "center" }}>
        {admin ? (
          <Box>
            <Typography sx={{ color: "white" }} variant="subtitle1">
              You are currently signed in as {admin.email} [{admin.role}]
            </Typography>
          </Box>
        ) : (
          <Box>
            <TextField
              id="email"
              sx={textFieldStyles}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorInEmail}
              label="Email"
              variant="standard"
              helperText={errorInEmail && "Fejl i email"}
              type="email"
              InputLabelProps={{ style: textFieldInputLabelProps }}
            />
            <TextField
              id="password"
              sx={textFieldStyles}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorInPassword}
              label="Password"
              variant="standard"
              helperText={errorInPassword && "Fejl i password"}
              type="password"
              InputLabelProps={{ style: textFieldInputLabelProps }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={dialogActionsStyles}>
        {admin ? (
          <Button sx={buttonStyles} onClick={logout}>
            Sign out
          </Button>
        ) : (
          <LoadingButton sx={buttonStyles} loading={loading} onClick={login}>
            Login
          </LoadingButton>
        )}
        <Button sx={buttonStyles} onClick={returnCallback}>
          Annuller
        </Button>
      </DialogActions>
    </Dialog>
  );
}
