import { createClient } from "@supabase/supabase-js";
import NotificationService from "./components/notification/NotificationService";

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL ?? "",
  process.env.REACT_APP_SUPABASE_KEY ?? ""
);

const signIn = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (data.user) {
    return data.user?.id;
  } else {
    NotificationService.showToast({
      text: "Forkert email eller password",
      severity: "error",
    });
  }
};

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  console.error(error);
};

export { signIn, signOut };
