function QuarterFinalsLines() {
  return (
    <div className="lines-container">
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "500px" }}></div>
      </div>
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "500px" }}></div>
      </div>
    </div>
  );
}

export default QuarterFinalsLines;
