import { TabContext, TabPanel } from "@mui/lab";
import { SxProps } from "@mui/material";
import { useEffect } from "react";
import Groups from "./groups";
import Topbar from "../../components/Topbar";
import TabBar from "../tabbar";

enum SCREENS {
  ALL = "ALL",
  GROUPS = "Groups",
}

const tabPanelStyles: SxProps = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  padding: 0,
  width: "90%",
  marginTop: "20px",
};

function Table() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <div className="content-container">
        <TabContext value={SCREENS.GROUPS}>
          <TabPanel sx={tabPanelStyles} value={SCREENS.GROUPS}>
            <Groups />
          </TabPanel>
        </TabContext>
      </div>
      <TabBar />
    </>
  );
}

export default Table;
