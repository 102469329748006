import { useEffect, useState } from "react";
import { BottomNavigation, SxProps } from "@mui/material";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import TableRows from "@mui/icons-material/TableRows";
import { useNavigate } from "react-router-dom";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useAppSelector } from "../hooks";
import { KNOCK_OUT_TOURNAMENT_SIZE } from "../types/types";
import StarIcon from "@mui/icons-material/Star";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ROUTES } from "../constants";
import { getRelativePath } from "../utils";

const bottomNavigationStyle: SxProps = {
  backgroundColor: "#111111",
  width: "100%",
  height: "100%",
  borderTop: "1px solid #222222",
  ".MuiBottomNavigationAction-root": {
    color: "#AAAAAA",
  },
  ".MuiBottomNavigationAction-root.Mui-selected": {
    color: "#ff6b00",
  },
  ".MuiBottomNavigationAction-label": {
    fontSize: "10px !important",
  },
};

function TabBar() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const admin = useAppSelector((state) => state.app.admin);
  const knockoutTournamentSize = useAppSelector((state) => state.puljer.knockoutTournamentSize);

  useEffect(() => {
    switch (getRelativePath(window.location.pathname)) {
      case ROUTES.ROOT:
        setValue(0);
        break;
      case ROUTES.MATCHES:
        setValue(0);
        break;
      case ROUTES.MATCH_DETAILS:
        setValue(0);
        break;
      case ROUTES.TEAM_MATCHES:
        setValue(2);
        break;
      case ROUTES.FAVORITES:
        setValue(1);
        break;
      case ROUTES.GROUPS:
        setValue(2);
        break;
      case ROUTES.KNOCK_OUT:
        setValue(3);
        break;
      case ROUTES.TOURNAMENT:
        setValue(4);
        break;
      default:
        console.error("error with tabbar-path", window.location.pathname);
    }
  }, []);

  return (
    <div className="tab-bar">
      <BottomNavigation
        sx={bottomNavigationStyle}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <MuiBottomNavigationAction onClick={() => navigate("/matches")} label="Matches" icon={<SportsSoccerIcon />} />
        <MuiBottomNavigationAction onClick={() => navigate("/favorite-matches")} label="Favorites" icon={<StarIcon />} />
        <MuiBottomNavigationAction onClick={() => navigate("/table")} label="Groups" icon={<TableRows />} />
        {knockoutTournamentSize && knockoutTournamentSize > KNOCK_OUT_TOURNAMENT_SIZE.NONE && admin && (
          <MuiBottomNavigationAction onClick={() => navigate("/knockout")} label="Knockout" icon={<EmojiEventsIcon />} />
        )}
        <MuiBottomNavigationAction onClick={() => navigate("/tournament")} label="Tournament" icon={<EmojiEventsIcon />} />
      </BottomNavigation>
    </div>
  );
}

export default TabBar;
