import { useAppSelector } from "../../hooks";

import { MATCH_TYPE } from "../../types/types";
import Group from "./group";

function Groups() {
  const groupMatches = useAppSelector((state) => state.matches.groupMatches).filter(
    (match) => match.matchType === MATCH_TYPE.GROUP_MATCH
  );
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const _groups = useAppSelector((state) => state.groups.groups);
  const groups = _groups.filter((group) => (group.pulje as any).id === selectedPulje?.id);

  return (
    <>
      {groups.map((group) =>
        <Group groupMatches={groupMatches} group={group} />
      )}
    </>
  );
}

export default Groups;
