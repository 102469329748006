import { useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { TeamType, UpdateTeamType } from "../../../types/types";
import { dbCreateTeam, dbUpdateTeam, uploadFile } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import { Box, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { createNewTeam, setTeams } from "../../../features/teams/TeamsSlice";
import { addTeamToGroup } from "../../../features/groups/GroupsSlice";

interface Props {
  selectedTeam?: TeamType;
}

const CreateTeam = (props: Props) => {
  const editingTeam = useAppSelector((state) => state.teams.editingTeam);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const clubs = useAppSelector((state) => state.clubs.clubs);
  const groups = useAppSelector((state) => state.groups.groups).filter((g) => g.pulje.id === selectedPulje?.id);

  const [teamName, setTeamName] = useState(editingTeam?.teamName ?? "");
  const [groupId, setGroupId] = useState(editingTeam?.group?.id ?? groups[0]?.id);
  const teams = useAppSelector((state) => state.teams.teams);
  const [clubId, setClubId] = useState(editingTeam?.club?.id);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const _handleClose = () => {
    setTeamName("");
    navigate(ROUTES.ADMIN_TEAMS);
  };

  const submitNewTeam = () => {
    const teamExist = teams.filter((t) => t.group?.pulje === selectedPulje?.id).some((team) => team.teamName === teamName);
    if (teamExist) {
      NotificationService.showToast({
        severity: "error",
        text: "Dette hold eksisterer allerede.",
        duration: 3000,
      });
      return;
    }

    const group = groups.find((group) => group.id === groupId);
    const club = clubs.find((club) => club?.id === clubId);

    dbCreateTeam({
      teamName,
      group: groupId,
      club: clubId,
    })
      .then((key: string) => {
        dispatch(createNewTeam({ id: key, teamName, group, club: club ?? undefined }));
        dispatch(addTeamToGroup({ groupId, team: { id: key, teamName, group, club: club ?? undefined } }));
        _handleClose();
      })
      .catch(() => {
        alert("Error, cound not create team.");
      });
  };

  const updateTeamHandler = () => {
    if (!editingTeam) return;

    const newDbTeam: UpdateTeamType = {
      teamName,
      group: groupId,
      club: clubId === "Ingen klub" ? null : clubId,
    };

    dbUpdateTeam(editingTeam.id, newDbTeam)
      .then(() => {
        const updatedTeams = teams.map((team) => {
          if (team.id === editingTeam.id) {
            return {
              ...team,
              teamName,
              group: groups.find((group) => group.id === groupId),
              club: clubs.find((club) => club?.id === clubId) ?? undefined,
            };
          } else {
            return team;
          }
        });
        dispatch(setTeams(updatedTeams));
        NotificationService.showToast({
          severity: "success",
          text: "Holdet blev opdateret.",
          duration: 3000,
        });
        navigate(ROUTES.ADMIN_TEAMS);
      })
      .catch((error) => {
        NotificationService.showToast({
          severity: "error",
          text: "Holdet kunne ikke opdateres.",
          duration: 3000,
        });
        console.error(error);
      });
  };

  return (
    <>
      <Container maxWidth={"sm"}>
        <Stack spacing={3}>
          <Typography variant="h4">{editingTeam ? "Opdater hold" : "Opret hold"}</Typography>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader subheader="Udfyld følgende oplysninger:" title={editingTeam ? "Opdater hold" : "Nyt hold"} />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={8} md={8} padding={1} item>
                        <TextField
                          fullWidth
                          label="Holdnavn"
                          name="teamName"
                          onChange={(e) => setTeamName(e.target.value)}
                          required
                          value={teamName}
                        />
                      </Grid>

                      <Grid xs={4} md={4} padding={1} item>
                        <TextField
                          fullWidth
                          label="Gruppe"
                          name="group"
                          onChange={(e) => setGroupId(e.target.value)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={groupId}
                        >
                          {groups.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid xs={4} md={4} padding={1} item>
                        <TextField
                          fullWidth
                          label="Klub"
                          name="club"
                          onChange={(e) => setClubId(e.target.value)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={clubId}
                        >
                          <option key={"00"} value={undefined}>
                            Ingen klub
                          </option>
                          {clubs.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_TEAMS)}>
                    Fortryd
                  </Button>
                  <Button disabled={!teamName} variant="contained" onClick={editingTeam ? updateTeamHandler : submitNewTeam}>
                    {editingTeam ? "Opdater" : "Opret"}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreateTeam;
