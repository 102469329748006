import { Club, TeamType } from "../../types/types";
import { getGuid } from "../../utils";

export enum Nationality {
  DENMARK = "DENMARK",
  NORWAY = "NORWAY",
  NETHERLANDS = "NETHERLANDS",
  ENGLAND = "ENGLAND",
  FRANCE = "FRANCE",
  SWITZERLAND = "SWITZERLAND",
  BELGIUM = "BELGIUM",
}

const DantamIf = { id: "dantamif", name: "Dantam IF", logo: "" } as Club;
const UniqueFc = { id: "uniquefc", name: "Unique FC", logo: "" } as Club;
const TfcFredericia = { id: "tfcFredericia", name: "TFC Fredericia", logo: "" } as Club;
const Nordavind = { id: "nordavind", name: "Nordavind", logo: "" } as Club;
const Stovner = { id: "stovner", name: "Stovner", logo: "" } as Club;
const Sønderborg = { id: "sønderborg", name: "Sønderborg", logo: "" } as Club;
const Otsc = { id: "otsc", name: "OTSC", logo: "" } as Club;
const Crew74 = { id: "crew74", name: "Crew74", logo: "" } as Club;
const Gtfc = { id: "gtfc", name: "GTFC", logo: "" } as Club;
const ScEverest = { id: "sceverest", name: "SC Everest", logo: "" } as Club;
const Hobro = { id: "hobro", name: "Hobro", logo: "" } as Club;
const AarhusSK = { id: "aarhussk", name: "Aarhus SK", logo: "" } as Club;
const Lewisham = { id: "lewishham", name: "Lewisham United", logo: "" } as Club;
const NTSK = { id: "ntsk", name: "NTSK", logo: "" } as Club;
const ScRoyalBern = { id: "scroyalbern", name: "SC Royal Bern", logo: "" } as Club;
const ElevenStars = { id: "11stars", name: "11 Stars", logo: "" } as Club;
const TfcBreda = { id: "tfcbreda", name: "TFC Breda", logo: "" } as Club;
const CsEelavar = { id: "cseelavar", name: "CS Eelavar", logo: "" } as Club;
const Drammen = { id: "drammen", name: "Drammen", logo: "" } as Club;
const FcAce = { id: "fcace", name: "FC Ace", logo: "" } as Club;
const Illampuyal = { id: "illampuyal", name: "Illampuyal", logo: "" } as Club;
const StPatricks = { id: "stpatricks", name: "St. Patricks", logo: "" } as Club;

export interface ExtendedTeamType extends TeamType {
  nationality: Nationality;
  potMatches: number[];
  potId: number;
  opponents: Array<ExtendedTeamType>;
}

export interface Pot {
  potId: number;
  teams: Array<ExtendedTeamType>;
}

export const pot1 = {
  potId: 1,
  teams: [
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam IF A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: DantamIf,
      teamName: "Dantam IF B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique FC Red",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcFredericia,
      teamName: "TFC Fredericia White",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Nordavind,
      teamName: "Nordavind White",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Stovner,
      teamName: "Stovner Giants",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Sønderborg,
      teamName: "Sønderborg A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 1,
      opponents: [],
    },
  ],
} as Pot;

export const pot2 = {
  potId: 2,
  teams: [
    {
      id: getGuid(),
      club: UniqueFc,
      teamName: "Unique FC Black",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Otsc,
      teamName: "OTSC B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Crew74,
      teamName: "Crew74 Wolves",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Gtfc,
      teamName: "GTFC A",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: ScEverest,
      teamName: "SC Everest A",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro Black",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },

    {
      id: getGuid(),
      club: AarhusSK,
      teamName: "Aarhus SK White",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Stovner,
      teamName: "Stovner Lions",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 2,
      opponents: [],
    },
  ],
} as Pot;

export const pot3 = {
  potId: 3,
  teams: [
    {
      id: getGuid(),
      club: Hobro,
      teamName: "Hobro Blue",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcFredericia,
      teamName: "TFC Fredericia Blue",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Lewisham,
      teamName: "Lewisham United",
      nationality: Nationality.ENGLAND,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Gtfc,
      teamName: "GTFC B",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: NTSK,
      teamName: "NTSK",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: AarhusSK,
      teamName: "Aarhus SK Blue",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Crew74,
      teamName: "Crew74 Warriors",
      nationality: Nationality.DENMARK,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
    {
      id: getGuid(),
      club: ScRoyalBern,
      teamName: "SC Royal Bern",
      nationality: Nationality.SWITZERLAND,
      potMatches: [],
      potId: 3,
      opponents: [],
    },
  ],
} as Pot;

export const pot4 = {
  potId: 4,
  teams: [
    {
      id: getGuid(),
      club: ElevenStars,
      teamName: "11 Stars",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: TfcBreda,
      teamName: "TFC Breda",
      nationality: Nationality.NETHERLANDS,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: CsEelavar,
      teamName: "CS Eelavar",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Drammen,
      teamName: "Drammen",
      nationality: Nationality.NORWAY,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: FcAce,
      teamName: "FC Ace A",
      nationality: Nationality.NETHERLANDS,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: FcAce,
      teamName: "FC Ace B",
      nationality: Nationality.NETHERLANDS,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: Illampuyal,
      teamName: "Ilampuyal",
      nationality: Nationality.BELGIUM,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
    {
      id: getGuid(),
      club: StPatricks,
      teamName: "St. Patricks",
      nationality: Nationality.FRANCE,
      potMatches: [],
      potId: 4,
      opponents: [],
    },
  ],
} as Pot;
