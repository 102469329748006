// Import Swiper React components
import { SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import Slider from "react-slick";
import { ROUTES } from "../../constants";
import NoFavorites from "../other/noFavorites";
import Topbar from "../../components/Topbar";
import MatchList from "./matchList";
import TabBar from "../tabbar";
import UpdateLocationDialog from "../../components/update-location-dialog";

function GroupMatches() {
  const favoriteTeams = useAppSelector((state) => state.teams.favoriteTeams);
  const pathname = useLocation().pathname;
  const isMatchesView = pathname === ROUTES.ROOT || pathname === ROUTES.MATCHES;
  const locations = useAppSelector((state) => state.locations.locations);
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
  const sliderRef = useRef<any>(null);
  const [showDialog, setShowDialog] = useState(false);
  const admin = useAppSelector((state) => state.app.admin);

  useEffect(() => {
    sliderRef.current?.slickGoTo(selectedLocationIndex);
  }, [selectedLocationIndex]);

  return (
    <>
      <UpdateLocationDialog
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        selectedLocation={locations[selectedLocationIndex]}
      />
      <Topbar />
      <div className="content-container">
        {isMatchesView ? (
          <>
            <Tabs
              style={{
                color: "white",
                position: "fixed",
                top: 70,
                zIndex: 1,
                backgroundColor: "#111111",
                padding: "0px 10px 5px 10px",
                borderEndEndRadius: "20px",
                borderEndStartRadius: "20px",
                minHeight: 0,
                boxShadow: `black 0px 0px 20px 5px`,
              }}
              value={selectedLocationIndex}
              onChange={(_, value) => setSelectedLocationIndex(value)}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "white",
                },
              }}
            >
              {locations.map((location) => (
                <Tab
                  key={location.id}
                  style={{ color: "white", fontSize: "12px", margin: "0px 10px", minHeight: 0, height: "15px" }}
                  label={`${location.location}`}
                  onDoubleClick={() => admin && setShowDialog(true)}
                />
              ))}
            </Tabs>
            <div className="slider-container" style={{ width: "100%", backgroundColor: "transparent", marginTop: "20px" }}>
              <Slider
                beforeChange={(current, next) => setSelectedLocationIndex(next)}
                ref={sliderRef}
                infinite={false}
                speed={300}
                slidesToShow={1}
                centerMode
                centerPadding="0"
                arrows={false}
                touchThreshold={10}
                adaptiveHeight
              >
                {locations.map((location, i) => (
                  <SwiperSlide
                    key={location.id}
                    tabIndex={i}
                    style={{ display: "flex", backgroundColor: "transparent", border: "1px solid blue" }}
                  >
                    <MatchList
                      key={location.id}
                      isMatchesView={isMatchesView}
                      location={location}
                      selectedLocation={locations[selectedLocationIndex]}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          </>
        ) : (
          <>{favoriteTeams.length === 0 ? <NoFavorites /> : <MatchList isMatchesView={isMatchesView} />}</>
        )}
      </div>
      <TabBar />
    </>
  );
}
export default GroupMatches;
