import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Club, GroupType, TeamType } from "../../types/types";

// Define a type for the slice state
interface ClubState {
  clubs: Array<Club | null>;
}

// Define the initial state using that type
const initialState: ClubState = {
  clubs: [],
};

export const ClubsSlice = createSlice({
  name: "clubs",
  initialState,
  reducers: {
    setClubs: (state, action: PayloadAction<Array<Club>>) => {
      state.clubs = action.payload.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
});

export const { setClubs } = ClubsSlice.actions;

export default ClubsSlice.reducer;
