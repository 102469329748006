import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Settings } from "../../types/types";

// Define a type for the slice state
interface SettingsState {
  settings?: Settings;
}

// Define the initial state using that type
const initialState: SettingsState = {
  settings: undefined,
};

export const ClubsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload;
    },
  },
});

export const { setSettings } = ClubsSlice.actions;

export default ClubsSlice.reducer;
