import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Fragment, useState } from "react";
import { useAppSelector } from "../hooks";
import { SvgIconClasses, SxProps } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

interface Props {
  onItemSelected: (any: any) => void;
  options: { id: string; label: string }[];
  selectedItem: any;
  Icon: React.ElementType;
}

const drawerStyles: SxProps = {
  backgroundColor: "rgba(0, 0, 0, 0.8)", //Backdrop color

  ".MuiDrawer-paper": {
    backgroundColor: "rgba(0, 0, 0, 0)", //drawe menu background color
    boxShadow: "none",
    margin: "30px 20px",
    color: "white",
  },
};

const DrawerMenu = (props: Props) => {
  const { onItemSelected, options, selectedItem, Icon } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onItemSelectedHandler = (e: any, item: any) => {
    e.stopPropagation();
    onItemSelected(item);
    setIsDrawerOpen(false);
  };

  return (
    <Fragment>
      <Icon
        fontSize={"large"}
        onClick={() => setIsDrawerOpen(true)}
        sx={{ ":hover": { cursor: "pointer" }, color: "white" }}
      />

      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} sx={drawerStyles}>
        <Box role="presentation" onClick={() => setIsDrawerOpen(true)}>
          <List>
            {options.map((option, index) => (
              <ListItem key={option.id} disablePadding>
                <ListItemButton onClick={(e) => onItemSelectedHandler(e, option)}>
                  <ListItemText
                    sx={option.id === selectedItem.id ? { color: "#ffffff", borderBottom: "1px solid #ffffff" } : {}}
                    primary={option.label}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default DrawerMenu;
