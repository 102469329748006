import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastModel } from "../../components/notification/Toast";

interface NotificationState {
  toast?: ToastModel;
}

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {} as NotificationState,
  reducers: {
    addToast: (state: NotificationState, action: PayloadAction<{ toast: ToastModel }>) => {
      state.toast = action.payload.toast;
    },
    removeToast: (state: NotificationState) => {
      state.toast = undefined;
    },
  },
});

export const { addToast, removeToast } = notificationSlice.actions;

export default notificationSlice.reducer;
