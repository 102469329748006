import styled from "@emotion/styled";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, IconButton, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";
import { Severity } from "../../types/types";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";

interface Props {
  severity: Severity;
  text: string;
  onClose: () => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      sx={{ color: props.severity === "success" ? "white" : "black" }}
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

const AlertComponent = (props: Props) => {
  const { severity, text, onClose } = props;

  return (
    <Snackbar open>
      <Alert severity={severity}>{text}</Alert>
    </Snackbar>
  );
};

export default AlertComponent;
