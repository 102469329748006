import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types/types";

interface AppState {
  isApplicationBootstrapped: boolean;
  loading: boolean;
  admin?: User;
}

// Define the initial state using that type
const initialState: AppState = {
  isApplicationBootstrapped: false,
  loading: false,
  admin: undefined,
};
export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsApplicationBootstrapped: (state, action: PayloadAction<boolean>) => {
      state.isApplicationBootstrapped = action.payload;
    },
    setIsApplicationLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAdmin: (state, action: PayloadAction<User | undefined>) => {
      state.admin = action.payload;
    },
  },
});

export const { setIsApplicationBootstrapped, setIsApplicationLoading, setAdmin } = AppSlice.actions;

export default AppSlice.reducer;
