import { useState, useMemo, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LocationType, Match } from "../../../types/types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Button, Container, Stack, SvgIcon } from "@mui/material";
import { dbDeleteGroupMatch, dbMatchType, dbUploadGroupMatches } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import CheckIcon from "@mui/icons-material/Check";
import useConfirm from "../../../hooks/useConfirm";
import { Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import UndoIcon from "@mui/icons-material/Undo";
import AdminMatch from "./MatchCard";
import { updateTimes } from "../../../updateTimes";
import { deleteGroupMatch, setEditingMatch } from "../../../features/matches/matchesSlice";
import { calculateOverlap } from "../../../utils";
import ReorderIcon from "@mui/icons-material/Reorder";

interface Column {
  items: Match[];
  location: LocationType;
}

interface Props { }

const Matches = (props: Props) => {
  const matches = [...useAppSelector((state) => state.matches.groupMatches)].sort((a, b) => a.startTime - b.startTime);
  const [overlap, setOverlap] = useState<{ matchId1: string; matchId2: string; overlappingTeamId?: string }>();
  const [Dialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil slette denne kamp?");
  const tournament = useAppSelector((state) => state.tournament.tournament);
  const gapTime = useAppSelector((state) => state.settings.settings)?.gapTime;
  const navigate = useNavigate();
  const _locations = useAppSelector((state) => state.locations.locations);
  const dispatch = useAppDispatch();

  const [initialColumns, setInitialColumns] = useState<any>();
  const [tempColumns, setTempColumns] = useState<any>();

  useEffect(() => {
    const newLocations = _locations.reduce(
      (a, v) => ({ ...a, [v.id]: { location: v, items: matches.filter((match) => match.location.id === v.id) } }),
      {}
    );

    setInitialColumns(newLocations);
    setTempColumns(newLocations);
  }, []);

  /*
     useEffect(() => {
    dispatch(setEditingMatch(undefined));
  }, []);
  */
  useEffect(() => {
    checkForOverlaps();
  }, [tempColumns]);

  const matchesHasNotBeenReordered = () => {
    return JSON.stringify(initialColumns) === JSON.stringify(tempColumns);
  };

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return; // Rammer ved siden af
    const { source, destination } = result;

    // Der bliver flyttet et kort fra en kolonne til en anden!
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const result = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: updateTimes(sourceItems, tournament.date, gapTime ?? 0),
        },
        [destination.droppableId]: {
          ...destColumn,
          items: updateTimes(destItems, tournament.date, gapTime ?? 0),
        },
      };

      setColumns(result);
    } else {
      //Et kort bliver omrokeret i sin egen kolonne
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      const result = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: updateTimes(copiedItems, tournament.date, gapTime ?? 0),
        },
      };

      setColumns(result);
    }
  };

  const checkForOverlaps = () => {
    const newMatches = getCurrentMatches();

    const overlap = calculateOverlap(newMatches);

    if (overlap) {
      setOverlap(overlap);
    } else {
      setOverlap(undefined);
    }
  };

  const getCurrentMatches = () => {
    if (!tempColumns) return [];
    const newMatches: Match[] = [];
    for (const [_, column] of Object.entries(tempColumns)) {
      (column as Column).items.forEach((match) => {
        newMatches.push({
          ...match,
          location: (column as Column).location,
        } as Match);
      });
    }
    return newMatches;
  };

  const save = () => {
    const uploadData = getCurrentMatches().map((match) => {
      return {
        ...match,
        awayTeam: match.awayTeam?.id,
        group: match.group?.id,
        homeTeam: match.homeTeam?.id,
        location: match.location.id,
        pulje: match.pulje.id,
        tournament: match.tournament.id,
      } as dbMatchType;
    });
    dbUploadGroupMatches(uploadData)
      .then(() => {
        setInitialColumns(tempColumns);
        NotificationService.showToast({
          severity: "success",
          text: "Kampprogrammet blev opdateret!",
          duration: 3000,
        });
      })
      .catch(() => {
        NotificationService.showToast({
          severity: "error",
          text: "Kampprogrammet kunne ikke opdateres.",
          duration: 3000,
        });
      });
  };

  const onDeleteMatchHandler = async (matchId: string) => {
    let locationId = "";
    for (const [key, value] of Object.entries(tempColumns)) {
      if ((value as Column).items.some((match) => match.id === matchId)) {
        locationId = key;
        break;
      }
    }

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbDeleteGroupMatch(matchId).then(() => {
        dispatch(deleteGroupMatch(matchId));

        const updatedColumns = {
          ...tempColumns,
          [locationId]: {
            ...tempColumns[locationId],
            items: tempColumns[locationId].items.filter((match: Match) => match.id !== matchId),
          },
        };

        setTempColumns(updatedColumns);
        setInitialColumns(updatedColumns);
      });
    }
  };

  /*   const editMatchHandler = (match: Match) => {
    dispatch(setEditingMatch(match));
    navigate(ROUTES.ADMIN_CREATE_MATCH);
  }; */

  const getDelay = (location: LocationType) => {
    const delay = _locations.find((l) => l.id === location.id)?.delay ?? 0;

    if (delay > 0) {
      return `${delay} min`;
    } else if (delay < 0) {
      return `${-1 * delay} min`;
    } else {
      return "";
    }
  };

  const getDelayColor = (location: LocationType) => {
    const delay = _locations.find((l) => l.id === location.id)?.delay ?? 0;

    if (delay > 0) {
      return `red`;
    } else if (delay < 0) {
      return `green`;
    } else {
      return "";
    }
  };

  return (
    <>
      {Dialog()}
      <Stack
        spacing={3}
        justifyContent={matches.length > 0 ? "end" : "start"}
        height={"calc(100vh - 64px)"}
        marginLeft={"50px"}
      >
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Kampe</Typography>
          <div style={{ marginRight: "30px" }}>
            {matchesHasNotBeenReordered() ? (
              <>
                {matches.length === 0 && (
                  <Button
                    variant="contained"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <PlusIcon />
                      </SvgIcon>
                    }
                    onClick={() => navigate(ROUTES.ADMIN_GENERATE_TOURNAMENT)}
                  >
                    Generer kampe
                  </Button>
                )}
                {matches.length > 0 && tempColumns && (
                  <>
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="contained"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <PlusIcon />
                          </SvgIcon>
                        }
                        onClick={() => navigate(ROUTES.ADMIN_CREATE_MATCH)}
                      >
                        Opret kamp
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ReorderIcon />
                          </SvgIcon>
                        }
                        onClick={() => navigate(ROUTES.ADMIN_TABLE)}
                      >
                        Se tabel
                      </Button>
                    </Stack>
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CheckIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={save}
                  sx={{ marginRight: "10px" }}
                >
                  Gem ændringer
                </Button>
                <Button
                  variant="outlined"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <UndoIcon />
                    </SvgIcon>
                  }
                  onClick={() => setTempColumns(initialColumns)}
                >
                  Fortryd
                </Button>
              </>
            )}
          </div>
        </Stack>
        {matches.length > 0 && tempColumns && (
          <div
            style={{
              overflow: "scroll",
              height: "80vh",
            }}
          >
            <Stack direction="row">
              <DragDropContext onDragEnd={(result) => onDragEnd(result, tempColumns, setTempColumns)}>
                <div
                  style={{
                    display: "flex",
                    minHeight: "80vh",
                    justifyContent: "flex-start",
                  }}
                >
                  {Object.entries(tempColumns).map(([locationId, column], index) => {
                    return (
                      <Droppable key={locationId} droppableId={locationId}>
                        {(provided, snapshot) => (
                          <div
                            style={{
                              minHeight: "100px",
                              display: "flex",
                              flexDirection: "column",
                              background: "white",
                              minWidth: "400px",
                              borderRadius: "15px",
                              padding: "15px",
                              marginRight: "30px",
                              boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
                            }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                              <span
                                style={{
                                  color: "#10957d",
                                  background: "rgba(16, 149, 125, 0.15)",
                                  padding: " 2px 10px",
                                  borderRadius: "5px",
                                  alignSelf: "flex-start",
                                }}
                              >
                                {`${(column as Column).location.location}`}
                              </span>
                              <span
                                style={{
                                  color: getDelayColor((column as Column).location),
                                  padding: " 2px 10px",
                                  borderRadius: "5px",
                                  alignSelf: "flex-start",
                                }}
                              >
                                {`${getDelay((column as Column).location)}`}
                              </span>
                            </div>
                            {(column as Column).items &&
                              (column as Column).items.map((item: Match, index: number) => (
                                <AdminMatch
                                  key={item.id}
                                  match={item}
                                  index={index}
                                  onDelete={onDeleteMatchHandler}
                                  overlap={overlap}
                                />
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
              </DragDropContext>
            </Stack>
          </div>
        )}
      </Stack>
    </>
  );
};

export default Matches;
