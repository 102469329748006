import { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { getTournaments } from "../../api/api";
import { formatDate3 } from "../../utils";
import moment from "moment";
import { Tournament } from "../../types/types";
import { getSportType } from "../dantam/utils";

function FrontPage() {
  const [dates, setDates] = useState<Tournament[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTournaments(getSportType())
      .then((data: any) => {
        setDates(data.sort((a: any, b: any) => a.date - b.date));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="front-page-container">
      <img className="front-page-image" />
      <div className="frontpage-content-container">
        <Typography sx={{ textAlign: "center", color: "white" }} variant="h4">
          TOURNAMENT DATES
        </Typography>
        {isLoading ? (
          <CircularProgress
            sx={{
              color: "white",
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              bottom: "60%",
              textAlign: "center",
            }}
          />
        ) : (
          <div className="dates-container">
            {dates
              .filter((date) => date.showInTournamentDates)
              .map(({ date, name, link, id, isGoingLive }) => (
                <div key={id} className="tournament-row">
                  <a
                    style={{
                      display: "flex",
                      textDecoration: "none",
                      opacity: date > moment().unix() ? 1 : 0.25,
                      fontStyle: date > moment().unix() ? "normal" : "italic",
                    }}
                    target="_blank"
                    href={link ?? ""}
                  >
                    <Typography
                      className="tournament-text"
                      sx={{ color: "#ff6b00", paddingLeft: "5px", textTransform: "uppercase" }}
                      variant="body1"
                    >
                      {name}
                    </Typography>
                    <Typography
                      className="tournament-text"
                      sx={{
                        color: "white",
                        paddingLeft: "5px",
                        textTransform: "uppercase",
                      }}
                      variant="body1"
                    >
                      {formatDate3(date)}
                    </Typography>
                    {isGoingLive && <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'black',
                        backgroundColor: '#ff6b00',
                        marginLeft: '10px',
                        padding: '0px 10px',
                        borderRadius: '10px',
                        fontSize: '7px',
                        letterSpacing: '1px',
                        height: '16px',
                        fontWeight: 700
                      }}>
                      LIVESCORE
                    </Typography>
                    }
                  </a>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default FrontPage;
