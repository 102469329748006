import { MATCH_TYPE, STATUS } from "./types/types";
import { convertFirebaseObjectsList } from "./utils";
import { v4 as uuidv4 } from "uuid";

export const generateGroupMatches = (puljer, groups, locations, startTimeUnix, matchGapTimeInMinutes) => {
  let locationTimeMap = new Map();
  let locationIndex = 0;

  if (groups.length === 0 || locations.length === 0) return [];
  const matches = [];
  groups.forEach((group) => {
    group.teams?.forEach((homeTeam) => {
      const awayTeams = group.teams?.filter((team) => team.id !== homeTeam.id);
      awayTeams?.forEach((awayTeam) => {
        //Instantiate locationTimeMap
        if (locationTimeMap.size === 0) {
          locations.forEach((location, index) => {
            locationTimeMap.set(index, { location: location.id, startTime: startTimeUnix });
          });
        }

        if (locationIndex === locationTimeMap.size) locationIndex = 0;

        const startTime = locationTimeMap.get(locationIndex).startTime;
        const id = uuidv4();
        const duration = puljer.find((pulje) => pulje.id === group.pulje.id).groupMatchTime;
        const matchTime = duration * 60; // epoch miliseconds
        const endTime = startTime + matchTime;
        const location = locationTimeMap.get(locationIndex).location;

        if (checkIfMatchExists(convertFirebaseObjectsList(matches), homeTeam, awayTeam)) return;
        matches.push({
          id,
          homeTeam: homeTeam.id,
          awayTeam: awayTeam.id,
          homeTeamPoints: 0,
          awayTeamPoints: 0,
          startTime,
          endTime,
          matchType: 0,
          status: STATUS.PENDING,
          location: location,
          group: group.id,
          matchType: MATCH_TYPE.GROUP_MATCH,
          pulje: groups.find((_group) => _group.id === group.id).pulje,
          duration
        });

        locationTimeMap.set(locationIndex, {
          location: locationTimeMap.get(locationIndex).location,
          startTime: endTime + 60 * matchGapTimeInMinutes,
        });

        locationIndex++;
      });
    });
  });
  return matches;
};

const checkIfMatchExists = (matches, team1, team2) => {
  if (!matches) return;
  return matches.some(
    (match) =>
      (match.homeTeam === team1.id && match.awayTeam === team2.id) ||
      (match.awayTeam === team1.id && match.homeTeam === team2.id)
  );
};
