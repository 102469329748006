import { Match, MATCH_TYPE } from "../types/types";
import { fetchEarlierMatches } from "../api/api";
import { useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import { getKnockoutRoundType } from "../utils";
import { CircularProgress } from "@mui/material";
import { KnockOutMatches } from "../features/matches/matchesSlice";
import moment from "moment";

interface Props {
  match: Match;
}

function EarlierMatches(props: Props) {
  const { match } = props;
  const [earlierMatches, setEarlierMatches] = useState<Match[]>([]);
  const tournament = useAppSelector((state) => state.tournament.tournament);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const homeTeamClubId = match.homeTeam?.club?.id;
    const awayTeamClubId = match.awayTeam?.club?.id;
    if (!homeTeamClubId || !awayTeamClubId) return;
    setLoading(true);

    fetchEarlierMatches(homeTeamClubId, awayTeamClubId, tournament.id, match.pulje.puljeName)
      .then((data) => {
        if (data) {
          setEarlierMatches(data);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setEarlierMatches([]);
    };
  }, []);

  const getColor = (teamSide: "hometeam" | "awayteam", match: Match) => {
    if (teamSide === "hometeam") {
      if (match.homeTeamPoints > match.awayTeamPoints) {
        return "green";
      } else if (match.homeTeamPoints < match.awayTeamPoints) {
        return "red";
      } else {
        return "orange";
      }
    } else if (teamSide === "awayteam") {
      if (match.awayTeamPoints > match.homeTeamPoints) {
        return "green";
      } else if (match.awayTeamPoints < match.homeTeamPoints) {
        return "red";
      } else {
        return "orange";
      }
    }
  };

  const getMatchType = (match: Match) => {
    if (match.matchType === MATCH_TYPE.GROUP_MATCH) {
      return "Group-stage";
    } else if (match.matchType === MATCH_TYPE.KNOCKOUT_MATCH) {
      return getKnockoutRoundType(match.knockoutMatchId as keyof KnockOutMatches);
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress
          sx={{
            color: "white",
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            bottom: "60%",
            textAlign: "center",
          }}
        />
      ) : (
        <>
          <div className="earlier-matches-container">
            <div className="earlier-matches-header">HISTORY BETWEEN CLUBS</div>
            {earlierMatches.length > 0 ? (
              earlierMatches.map((match) => (
                <div key={match.id} className="earlier-matches-match">
                  <div className="earlier-match-row">
                    <div style={{ color: getColor("hometeam", match) }} className="earlier-match-team">
                      <img src={match.homeTeam?.club?.logo ?? ""} alt="" />
                      {match.homeTeam?.teamName ?? "Pending"}
                    </div>

                    <div className="earlier-match-result">{`${match.homeTeamPoints} - ${match.awayTeamPoints}`}</div>

                    <div style={{ color: getColor("awayteam", match) }} className="earlier-match-team">
                      <img src={match.awayTeam?.club?.logo ?? ""} alt="" />
                      {match.awayTeam?.teamName ?? "Pending"}
                    </div>
                  </div>
                  <div className="earlier-match-tournament-data">{`${match.tournament.name} ${moment
                    .unix(match.tournament.date)
                    .year()} (${match.pulje.puljeName} ${getMatchType(match)?.toLocaleLowerCase()})`}</div>
                </div>
              ))
            ) : (
              <p className="no-data">Ingen data</p>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default EarlierMatches;
