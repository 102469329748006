import { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { KnockOutMatches } from "../features/matches/matchesSlice";
import { useAppSelector } from "../hooks";
import { KNOCK_OUT_MATCH_TYPE, STATUS, Match } from "../types/types";
import { dbUpdateGroupMatch2 } from "../api/api";
import { matchExists } from "../utils";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useConfirm from "../hooks/useConfirm";
import NotificationService from "./notification/NotificationService";

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedKnockoutMatchKey?: keyof KnockOutMatches;
  knockOutMatchType: KNOCK_OUT_MATCH_TYPE;
  selectedMatch?: Match;
}

const CreateKnockoutMatchDialog = (props: Props) => {
  const { handleClose, open, selectedKnockoutMatchKey, selectedMatch } = props;

  const [homeTeamLabel, setHomeTeamLabel] = useState("");
  const [awayTeamLabel, setAwayTeamLabel] = useState("");
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");

  const teams = useAppSelector((state) => state.teams.teams);

  const [ConfirmDialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil genstarte denne kamp?");

  useEffect(() => {
    if (!selectedMatch) return;
    selectedMatch.homeTeam && setHomeTeam(selectedMatch.homeTeam.id);
    selectedMatch.awayTeam && setAwayTeam(selectedMatch.awayTeam.id);
    selectedMatch.homeTeamLabel && setHomeTeamLabel(selectedMatch.homeTeamLabel);
    selectedMatch.awayTeamLabel && setAwayTeamLabel(selectedMatch.awayTeamLabel);
  }, [selectedMatch]);

  const update = () => {
    if (!selectedKnockoutMatchKey || !selectedMatch?.id) return;

    const body = {
      id: selectedMatch.id,
      homeTeam: homeTeam || null,
      awayTeam: awayTeam || null,
      homeTeamLabel,
      awayTeamLabel,
    };

    dbUpdateGroupMatch2(body.id, body).catch((e) => {
      NotificationService.showToast({
        severity: "error",
        text: "Kunne ikke opdatere knockout kamp",
        duration: 5000,
      });
      console.error("Kunne ikke opdatere knockout kamp!");
    });

    _handleClose();
  };

  const _handleClose = () => {
    setHomeTeam("");
    setAwayTeam("");
    setHomeTeamLabel("");
    setAwayTeamLabel("");
    handleClose();
  };

  const reset = async () => {
    if (!selectedKnockoutMatchKey || !selectedMatch?.id) return;

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbUpdateGroupMatch2(selectedMatch.id, {
        id: selectedMatch.id,
        homeTeam: null,
        awayTeam: null,
        homeTeamPoints: 0,
        awayTeamPoints: 0,
        status: STATUS.PENDING,
        homeTeamLabel: null,
        awayTeamLabel: null,
      }).finally(() => {
        _handleClose();
      });
    }
  };

  return (
    <>
      {ConfirmDialog()}
      <Dialog open={open} onClose={_handleClose}>
        <DialogContent sx={{ width: "500px", height: "200px" }}>
          <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px", width: "100%" }}>
            <TextField
              type="text"
              sx={{ "& label": { color: "grey" }, width: "150px" }}
              label="Label"
              variant="standard"
              value={homeTeamLabel}
              onChange={(e: any) => setHomeTeamLabel(e.target.value)}
            />
            <TextField
              type="text"
              sx={{ "& label": { color: "grey" }, width: "150px" }}
              label="Label"
              variant="standard"
              value={awayTeamLabel}
              onChange={(e: any) => setAwayTeamLabel(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center", margin: "20px 0px", width: "100%" }}>
            <FormControl variant="outlined" style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-label">Hjemmehold</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={homeTeam}
                label="Hometeam"
                onChange={(e) => setHomeTeam(e.target.value)}
              >
                {teams
                  .filter((obj) => obj.group?.pulje.id === selectedMatch?.pulje.id)
                  .sort((a, b) => a.teamName.localeCompare(b.teamName))
                  .map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.teamName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: "200px" }}>
              <InputLabel>Udehold</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={awayTeam}
                label="Awayteam"
                onChange={(e) => setAwayTeam(e.target.value)}
              >
                {teams
                  .filter((obj) => obj.group?.pulje.id === selectedMatch?.pulje.id)
                  .sort((a, b) => a.teamName.localeCompare(b.teamName))
                  .map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.teamName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px", width: "100%" }}>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={!matchExists(selectedMatch)} onClick={reset}>
            <RestartAltIcon />
          </Button>
          <Button onClick={_handleClose}>Annuller</Button>
          <Button variant="contained" onClick={update}>
            Opdater
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateKnockoutMatchDialog;
