import { useParams } from "react-router-dom";
import Topbar from "../../components/Topbar";
import { useAppSelector } from "../../hooks";
import TabBar from "../tabbar";
import ErrorPage from "../other/errorPage";
import { useMemo } from "react";
import MatchComponent from "../../components/match";
import { Match } from "../../types/types";

function TeamMatches() {
  const { id } = useParams();
  const team = useAppSelector((state) => state.teams.teams).find((team) => team.id === id);
  const matches = useAppSelector((state) => state.matches.groupMatches);
  if (!team) return <ErrorPage />;

  const filteredMatches = useMemo(() => {
    return matches.filter((match) => match.homeTeam?.id === id || match.awayTeam?.id === id);
  }, [matches]);

  return (
    <>
      <Topbar />
      <div className="content-container">
        <div className="match-list">
          {filteredMatches && filteredMatches.length > 0 && (
            <>
              <div className="matches-list-header">Matches</div>
              {filteredMatches?.map((match: Match) => (
                <MatchComponent key={match.id} match={match} />
              ))}
            </>
          )}
        </div>
      </div>
      <TabBar />
    </>
  );
}
export default TeamMatches;
