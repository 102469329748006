import { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "@mui/material/Button";
import useConfirm from "../../../hooks/useConfirm";
import { Card, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TableCellIconStyles } from "../../../styles/theme";
import { removePulje, setEditingPulje, setSelectedPulje } from "../../../features/puljer/PuljeSlice";
import NotificationService from "../../../components/notification/NotificationService";
import { Pulje } from "../../../types/types";
import { dbDeletePulje } from "../../../api/api";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

export default function Puljer() {
  const dispatch = useAppDispatch();
  const puljer = useAppSelector((state) => state.puljer.puljer);
  const matches = useAppSelector((state) => state.matches.groupMatches);
  const groups = useAppSelector((state) => state.groups.groups);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const tournament = useAppSelector((state) => state.tournament.tournament);

  const [Dialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil slette denne pulje?");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEditingPulje(undefined));
  }, []);

  const deletePuljeHandler = async (puljeId: string) => {
    const groupsUsingPulje = groups.filter((match) => match.pulje.id === puljeId);

    if (groupsUsingPulje.length > 0) {
      NotificationService.showToast({
        severity: "error",
        text: `Der er allerede oprettet ${groupsUsingPulje.length} gruppe(r) i denne pulje.`,
        duration: 3000,
      });
      return;
    }

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbDeletePulje(puljeId)
        .then(() => {
          dispatch(removePulje(puljeId));
          NotificationService.showToast({
            severity: "success",
            text: `Puljen blev slettet`,
            duration: 5000,
          });
        })
        .catch((e) => {
          console.error(e);
          NotificationService.showToast({
            severity: "error",
            text: `Puljen kunne ikke slettes.`,
            duration: 5000,
          });
        });
    }
  };

  const editPuljeHandler = (pulje: Pulje) => {
    dispatch(setEditingPulje(pulje));
    navigate(ROUTES.ADMIN_CREATE_PULJE);
  };

  // If selectedPulje is not found in allPuljer due to deletion select the first avaiable pulje
  useEffect(() => {
    if (!puljer.find((p) => p.id === selectedPulje?.id)) {
      dispatch(setSelectedPulje(puljer[0]));
    }
  }, [puljer, selectedPulje]);

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Puljer</Typography>
            <div>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => navigate(ROUTES.ADMIN_CREATE_PULJE)}
              >
                Opret
              </Button>
            </div>
          </Stack>
          <Card sx={{ width: "100%" }}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Pulje</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {puljer.map((pulje: Pulje) => {
                    return (
                      <TableRow hover key={pulje.id}>
                        <TableCell>
                          <Typography variant="subtitle2">{pulje.puljeName}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                        >
                          <ModeEditOutlineOutlinedIcon sx={TableCellIconStyles} onClick={() => editPuljeHandler(pulje)} />
                          <DeleteOutlineOutlinedIcon sx={TableCellIconStyles} onClick={() => deletePuljeHandler(pulje.id)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
