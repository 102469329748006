
export type Tournament = {
  id: string;
  name: string;
  date: number;
  link: string | null;
  isActive: boolean;
  showInTournamentDates: boolean;
  isGoingLive: boolean;
  sportType: SPORT_TYPE
};

export type Settings = {
  gapTime: number | null;
  id: string;
  isPublic: boolean;
  rules: string | null;
  text: string | null;
  title: string | null;
  tournament: string | null;
};

export type Match = {
  id: string;
  homeTeam?: TeamType;
  awayTeam?: TeamType;
  homeTeamPoints: number;
  awayTeamPoints: number;
  status: STATUS;
  startTime: number;
  endTime: number;
  location: LocationType;
  matchType: MATCH_TYPE;
  group?: GroupType;
  knockoutMatchId?: string;
  pulje: Pulje;
  tournament: Tournament;
  homeTeamLabel?: string;
  awayTeamLabel?: string;
  duration: number;
};

export enum MATCH_TYPE {
  GROUP_MATCH = "GROUP_MATCH",
  KNOCKOUT_MATCH = "KNOCKOUT_MATCH",
}

export type TeamType = {
  id: string;
  teamName: string;
  group?: GroupType;
  club?: Club;
};

export type Club = {
  id: string;
  name: string;
  logo?: string | null;
};

export type UpdateTeamType = {
  teamName: string;
  group: string;
  club?: string | null;
};

export type GroupType = {
  id: string;
  name: string;
  teams?: Array<TeamType>;
  pulje: Pulje;
};

export enum SPORT_TYPE {
  FOOTBALL = "FOOTBALL",
  VOLLEYBALL = "VOLLEYBALL",
}

export enum STATUS {
  PENDING = "PENDING",
  PLAYING = "PLAYING",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}

export enum KNOCK_OUT_TOURNAMENT_SIZE {
  NONE = "NONE",
  MINI = "MINI",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum KNOCK_OUT_MATCH_TYPE {
  ROUND_OF_16 = "Round of 16",
  QUARTER_FINAL = "Quarter-final",
  SEMI_FINAL = "Semi-final",
  THIRD = "Third place",
  FINAL = "Final",
}

export type LocationType = {
  id: string;
  location: string;
  tournament: Tournament;
  delay: number;
};

export enum ROLES {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}

export type Pulje = {
  groupMatchTime: number;
  id: string;
  knockoutMatchTime: number;
  puljeName: string;
  puljeSize: KNOCK_OUT_TOURNAMENT_SIZE;
  tournament: Tournament;
};

export type dbPulje = {
  groupMatchTime: number;
  id: string;
  knockoutMatchTime: number;
  puljeName: string;
  puljeSize: KNOCK_OUT_TOURNAMENT_SIZE
  tournament: string;
};

export interface User {
  email: string | null;
  expiration: number | null;
  firstname: string | null;
  id: string;
  lastname: string | null;
  role: ROLES
}

export enum ADMIN_VIEW {
  MATCHES = "Kampprogram",
  PULJER = "Puljer",
  GROUPS = "Grupper",
  TEAMS = "Hold",
  LOCATIONS = "Baner",
  SETTINGS = "Indstillinger",
}

export type Severity = "error" | "warning" | "success" | "info";
