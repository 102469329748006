import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useAppSelector } from "../../hooks";
import { KNOCK_OUT_TOURNAMENT_SIZE } from "../../types/types";
import FinalTeams from "./final/final-teams";
import QuarterFinalsLeftLines from "./quarter-finals/quarter-finals-lines";
import QuarterFinalsTeams from "./quarter-finals/quarter-finals-teams";
import RoundOf16Lines from "./round-of-16/round-of-16-lines";
import RoundOf16Teams from "./round-of-16/round-of-16-teams";
import SemiFinalsLines from "./semi-finals/semi-finals-lines";
import SemiFinalsTeams from "./semi-finals/semi-finals-teams";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import ThirdTeams from "./third/third-teams";
import { useEffect } from "react";
import Topbar from "../../components/Topbar";
import TabBar from "../tabbar";

function KnockOut() {
  const knockoutTournamentSize = useAppSelector((state) => state.puljer.knockoutTournamentSize);
  const { isDesktop } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    if (knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.NONE) {
      navigate("/matches");
    }
  }, [knockoutTournamentSize]);

  return (
    <>
      <Topbar />
      <div className="content-container">
        <div className="knockout-wrapper">
          <TransformWrapper
            initialScale={isDesktop ? 0.9 : 0.18}
            minScale={isDesktop ? 0.9 : 0.18}
            maxScale={isDesktop ? 2 : 1.2}
            centerOnInit
          >
            <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
              <div className="knockout-container">
                <ThirdTeams />
                <FinalTeams />
                <SemiFinalsLines />
                <SemiFinalsTeams />
                {(knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE ||
                  knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.SMALL) && (
                  <>
                    <QuarterFinalsLeftLines />
                    <QuarterFinalsTeams />
                  </>
                )}
                {knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE && (
                  <>
                    <RoundOf16Lines />
                    <RoundOf16Teams />
                  </>
                )}
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
      <TabBar />
    </>
  );
}

export default KnockOut;
