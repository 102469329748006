import {
  Match,
  STATUS,
  TeamType,
  KNOCK_OUT_MATCH_TYPE,
  MATCH_TYPE,
  Pulje,
  LocationType,
  GroupType,
  Tournament,
  KNOCK_OUT_TOURNAMENT_SIZE,
} from "./types/types";
import moment from "moment";
import { KnockOutMatches } from "./features/matches/matchesSlice";
import NotificationService from "./components/notification/NotificationService";
import { v4 as uuidv4 } from "uuid";
import ballLogo from "./assets/icons/ball-logo.png";

export const getGuid = () => {
  return uuidv4();
};

export const updateTimes = (matches: Match[], initialStartTime: number, gapTime: number, locations: LocationType[]) => {
  let locationTimeMap = new Map();
  let locationIndex = 0;
  const newMatches: Array<Match> = [];

  locations.forEach((location, index) => {
    locationTimeMap.set(index, { location: location, startTime: initialStartTime });
  });

  matches.forEach((match, index) => {
    const matchDuration = match.duration * 60;

    if (!matchDuration) {
      NotificationService.showToast({
        severity: "error",
        text: "Der skete en fejl. Kunne ikke opdatere tidsplanen.",
        duration: 5000,
      });
      console.error(`Could not find match duration for this match: ${match.id}`);
      return match;
    }

    if (locationIndex === locationTimeMap.size) locationIndex = 0;

    let startTime;
    let endTime;
    let location;

    if (index === 0) {
      location = locationTimeMap.get(locationIndex).location;

      newMatches.push({
        ...match,
        startTime: initialStartTime,
        endTime: initialStartTime + matchDuration,
        location: locationTimeMap.get(locationIndex).location,
      });

      locationTimeMap.set(locationIndex, {
        startTime: initialStartTime + matchDuration + gapTime,
        location: locationTimeMap.get(locationIndex).location,
      });
    } else {
      startTime = locationTimeMap.get(locationIndex).startTime;
      endTime = startTime + matchDuration;
      location = locationTimeMap.get(locationIndex).location;

      newMatches.push({
        ...match,
        startTime: startTime,
        endTime: startTime + matchDuration,
        location: location,
      });

      locationTimeMap.set(locationIndex, {
        location: locationTimeMap.get(locationIndex).location,
        startTime: endTime + gapTime,
      });
    }

    locationIndex++;
  });
  return newMatches;
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const matchExists = (match: Match | undefined) => {
  if (!match) return false;
  return (
    !!match.homeTeam ||
    !!match.awayTeam ||
    !!match.startTime ||
    !!match.endTime ||
    !!match.location ||
    !!match.homeTeamLabel ||
    !!match.awayTeamLabel
  );
};

// Takes a firebase snapshot.val() which is expected to contain a object of objects. Returns a list of objects.
export const convertFirebaseObjectsList = (snapshot: any) => {
  return Object.keys(snapshot).map((key) => {
    return { id: key, ...snapshot[key] };
  });
};

// Takes a firebase snapshot.val() which is expected to contain a ket-value pairs. Returns a list of objects.
export const convertGroups = (snapshot: any) => {
  return Object.keys(snapshot).map((key) => {
    return { id: key, ...snapshot[key] };
  });
};

export const getPlays = (teamId: string, matches: Array<Match>) => {
  const numberOfMatchesAsHomeTeam = matches.filter(
    (match) => match.homeTeam?.id === teamId && match.status === STATUS.FINISHED
  ).length;
  const numberOfMatchesAsAwayTeam = matches.filter(
    (match) => match.awayTeam?.id === teamId && match.status === STATUS.FINISHED
  ).length;
  return numberOfMatchesAsHomeTeam + numberOfMatchesAsAwayTeam;
};

export const getWins = (teamId: string, matches: Array<Match>) => {
  const numberOfWinsAsHomeTeam = matches.filter(
    (match) =>
      match.homeTeam?.id === teamId && match.homeTeamPoints > match.awayTeamPoints && match.status === STATUS.FINISHED
  ).length;
  const numberOfWinsAsAwayTeam = matches.filter(
    (match) =>
      match.awayTeam?.id === teamId && match.awayTeamPoints > match.homeTeamPoints && match.status === STATUS.FINISHED
  ).length;
  return numberOfWinsAsHomeTeam + numberOfWinsAsAwayTeam;
};

export const getDraws = (teamId: string, matches: Array<Match>) => {
  const numberOfDrawsAsHomeTeam = matches.filter(
    (match) =>
      match.homeTeam?.id === teamId && match.homeTeamPoints === match.awayTeamPoints && match.status === STATUS.FINISHED
  ).length;
  const numberOfDrawsAsAwayTeam = matches.filter(
    (match) =>
      match.awayTeam?.id === teamId && match.homeTeamPoints === match.awayTeamPoints && match.status === STATUS.FINISHED
  ).length;
  return numberOfDrawsAsHomeTeam + numberOfDrawsAsAwayTeam;
};

export const getLoses = (teamId: string, matches: Array<Match>) => {
  const numberOfLosesAsHomeTeam = matches.filter(
    (match) =>
      match.homeTeam?.id === teamId && match.homeTeamPoints < match.awayTeamPoints && match.status === STATUS.FINISHED
  ).length;
  const numberOfLosesAsAwayTeam = matches.filter(
    (match) =>
      match.awayTeam?.id === teamId && match.awayTeamPoints < match.homeTeamPoints && match.status === STATUS.FINISHED
  ).length;
  return numberOfLosesAsHomeTeam + numberOfLosesAsAwayTeam;
};

export const getGoalsFor = (teamId: string, matches: Array<Match>) => {
  const numberOfGoalsAsHomeTeam = matches
    .filter((match) => match.homeTeam?.id === teamId && match.status === STATUS.FINISHED)
    .map((match) => match.homeTeamPoints)
    .reduce((partialSum, a) => partialSum + a, 0);
  const numberOfGoalsAsAwayTeam = matches
    .filter((match) => match.awayTeam?.id === teamId && match.status === STATUS.FINISHED)
    .map((match) => match.awayTeamPoints)
    .reduce((partialSum, a) => partialSum + a, 0);
  return numberOfGoalsAsHomeTeam + numberOfGoalsAsAwayTeam;
};

export const getGoalsAway = (teamId: string, matches: Array<Match>) => {
  const numberOfGoalsAsHomeTeam = matches
    .filter((match) => match.homeTeam?.id === teamId && match.status === STATUS.FINISHED)
    .map((match) => match.awayTeamPoints)
    .reduce((partialSum, a) => partialSum + a, 0);
  const numberOfGoalsAsAwayTeam = matches
    .filter((match) => match.awayTeam?.id === teamId && match.status === STATUS.FINISHED)
    .map((match) => match.homeTeamPoints)
    .reduce((partialSum, a) => partialSum + a, 0);
  return numberOfGoalsAsHomeTeam + numberOfGoalsAsAwayTeam;
};

export const getGoalDifference = (teamId: string, matches: Array<Match>) => {
  return getGoalsFor(teamId, matches) - getGoalsAway(teamId, matches);
};

export const getPoints = (teamId: string, matches: Array<Match>) => {
  return getWins(teamId, matches) * 3 + getDraws(teamId, matches) * 1;
};

export const sortTeamsByMutuality = (_teams1: Array<TeamType>, matches: Array<Match>) => {
  const _teams = _teams1.map((team) => {
    {
      return {
        ...team,
        mPoints: 0,
        points: getPoints(team.id, matches),
      };
    }
  });

  // GROUP THE TEAMS WITH SAME POINTS
  const teamsWithSamePoints = new Map<number, TeamType[]>();

  _teams.forEach((team) => {
    const existingEntry = teamsWithSamePoints.get(team.points);
    if (existingEntry) {
      teamsWithSamePoints.set(team.points, [...existingEntry, team]);
    } else {
      teamsWithSamePoints.set(team.points, [team]);
    }
  });

  const mutualPointGroups = Array.from(teamsWithSamePoints)
    .map(([_, value]) => value)
    .filter((list) => list.length > 1);

  // FIND MATCHES FOR EACH GROUP
  const mpMutualMatchesGroups = mutualPointGroups.map((listOfTeamIds) => [
    ...matches.filter(
      (match) =>
        listOfTeamIds.map((group) => group.id).includes(match.homeTeam?.id ?? '') &&
        listOfTeamIds.map((group) => group.id).includes(match.awayTeam?.id ?? '')
    ),
  ]);

  // GIVE M-POINTS TO EACH RESPECTIVE GROUP
  mpMutualMatchesGroups.forEach((groupOfMatches) => {
    groupOfMatches.map((match) => {
      if (match.homeTeamPoints > match.awayTeamPoints) {
        const teamIndex = _teams.findIndex((team) => team?.id === match.homeTeam?.id);
        _teams[teamIndex]!.mPoints += 3;
      } else if (match.awayTeamPoints > match.homeTeamPoints) {
        const teamIndex = _teams.findIndex((team) => team?.id === match.awayTeam?.id);
        _teams[teamIndex]!.mPoints += 3;
      } else {
        const team1Index = _teams.findIndex((team) => team?.id === match.homeTeam?.id);
        const team2Index = _teams.findIndex((team) => team?.id === match.awayTeam?.id);

        _teams[team1Index]!.mPoints += 1;
        _teams[team2Index]!.mPoints += 1;
      }
    });
  });

  // SORT BY M-POINTS AND RETURN

  return _teams.sort((a, b) => (b?.mPoints ?? 0) - (a?.mPoints ?? 0));
};

export const sortTeamsByPoints = (_teams: Array<TeamType>, matches: Array<Match>) => {
  return _teams
    .map((team) => {
      return {
        ...team,
        points: getPoints(team.id, matches),
      };
    })
    .sort((a, b) => b.points - a.points);
};

export const sortTeamsByGoalsFor = (_teams: Array<TeamType>, matches: Array<Match>) => {
  return _teams
    .map((team) => {
      return {
        ...team,
        goalsFor: getGoalsFor(team.id, matches),
      };
    })
    .sort((a, b) => b.goalsFor - a.goalsFor);
};

export const sortTeamsByGoalDifference = (_teams: Array<TeamType>, matches: Array<Match>) => {
  const result = _teams
    .map((team) => {
      return {
        ...team,
        goalDifference: getGoalDifference(team.id, matches),
      };
    })
    .sort((a, b) => b.goalDifference - a.goalDifference);
  return result;
};

export const sortTeams = (_teams: Array<TeamType>, matches: Array<Match>, sortByMutuality: boolean) => {
  const goalsFor = sortTeamsByGoalsFor(_teams, matches);
  const goalDifference = sortTeamsByGoalDifference(goalsFor, matches);
  const mutuality = sortTeamsByMutuality(goalDifference, matches);
  return sortTeamsByPoints(sortByMutuality ? mutuality : goalDifference, matches);
};

export const getMatchStatusText = (status?: STATUS) => {
  switch (status) {
    case STATUS.PENDING:
      return "Pending";
    case STATUS.PLAYING:
      return "Playing";
    case STATUS.FINISHED:
      return "FT";
    default:
      return "?";
  }
};

export const formatDate = (unixTimestamp: number) => {
  return moment.unix(unixTimestamp).format("DD/MM/YYYY");
};

export const formatDate2 = (unixTimestamp: number) => {
  return moment.unix(unixTimestamp).format("MMM DD");
};

export const formatDate3 = (unixTimestamp: number) => {
  return moment.unix(unixTimestamp).format("MMM DD, YYYY");
};

export const formatDate4 = (unixTimestamp: number) => {
  return moment.unix(unixTimestamp).format("DD. MMM. YYYY");
};

export const formatTime = (unixTimestamp?: number) => {
  if (!unixTimestamp) return "-";
  if (isNaN(unixTimestamp)) return "-";
  return moment.unix(unixTimestamp).format("HH:mm");
};

export const formatTime2 = (unixTimestamp?: number) => {
  if (!unixTimestamp) return "-";
  if (isNaN(unixTimestamp)) return "-";
  return moment.unix(unixTimestamp).format("H:mm");
};

export const getKnockoutRoundType = (id: keyof KnockOutMatches) => {
  switch (id) {
    case "r16_1":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_2":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_3":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_4":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_5":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_6":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_7":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "r16_8":
      return KNOCK_OUT_MATCH_TYPE.ROUND_OF_16;
    case "q_1":
      return KNOCK_OUT_MATCH_TYPE.QUARTER_FINAL;
    case "q_2":
      return KNOCK_OUT_MATCH_TYPE.QUARTER_FINAL;
    case "q_3":
      return KNOCK_OUT_MATCH_TYPE.QUARTER_FINAL;
    case "q_4":
      return KNOCK_OUT_MATCH_TYPE.QUARTER_FINAL;
    case "s_1":
      return KNOCK_OUT_MATCH_TYPE.SEMI_FINAL;
    case "s_2":
      return KNOCK_OUT_MATCH_TYPE.SEMI_FINAL;
    case "t":
      return KNOCK_OUT_MATCH_TYPE.THIRD;
    case "f":
      return KNOCK_OUT_MATCH_TYPE.FINAL;
    default:
      return "";
  }
};

export const isObjectEmpty = (obj: any) => {
  if (obj === undefined) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const calculateOverlap = (matches: Array<Match>) => {
  for (let i = 0; i < matches.length; i++) {
    const filteredMatches = matches
      .filter((_match) => _match.id !== matches[i].id)
      .filter((match) => !!match.homeTeam?.id || !!match.awayTeam?.id);

    const foundMatches = filteredMatches.filter(
      (_match) =>
        _match.homeTeam?.id === matches[i].homeTeam?.id ||
        _match.awayTeam?.id === matches[i].homeTeam?.id ||
        _match.homeTeam?.id === matches[i].awayTeam?.id ||
        _match.awayTeam?.id === matches[i].awayTeam?.id
    );
    const found = foundMatches.find(
      (_match) =>
        (_match.startTime >= matches[i].startTime && _match.startTime <= matches[i].endTime) ||
        (_match.endTime >= matches[i].startTime && _match.endTime <= matches[i].endTime)
    );

    if (found) {
      return {
        matchId1: matches[i].id,
        matchId2: found.id,
        overlappingTeamId: getOverlappingTeamId(matches[i], found),
      };
    }
  }
};

const getOverlappingTeamId = (match1: Match, match2: Match) => {
  if (match1.homeTeam?.id === match2?.homeTeam?.id) return match1?.homeTeam?.id;
  if (match1.awayTeam?.id === match2?.awayTeam?.id) return match1?.awayTeam?.id;
  if (match1.homeTeam?.id === match2?.awayTeam?.id) return match1?.homeTeam?.id;
  if (match1.awayTeam?.id === match2?.homeTeam?.id) return match1?.awayTeam?.id;
  return "";
};

export const getTourrnamentTemplate = (pulje: Pulje) => {
  const template = [
    {
      id: getGuid(),
      knockoutMatchId: "r16_1",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_2",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_3",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_4",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_5",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_6",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_7",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "r16_8",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "q_1",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "q_2",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "q_3",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "q_4",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "s_1",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "s_2",
      pulje,
    },

    {
      id: getGuid(),
      knockoutMatchId: "t",
      pulje,
    },
    {
      id: getGuid(),
      knockoutMatchId: "f",
      pulje,
    },
  ];

  return template.slice(
    template.length - knockoutSizeConverter(pulje.puljeSize as KNOCK_OUT_TOURNAMENT_SIZE),
    template.length
  ) as Match[];
};

export const knockoutSizeConverter = (size: KNOCK_OUT_TOURNAMENT_SIZE) => {
  switch (size) {
    case KNOCK_OUT_TOURNAMENT_SIZE.NONE:
      return 0;
    case KNOCK_OUT_TOURNAMENT_SIZE.MINI:
      return 2;
    case KNOCK_OUT_TOURNAMENT_SIZE.SMALL:
      return 4;
    case KNOCK_OUT_TOURNAMENT_SIZE.MEDIUM:
      return 8;
    case KNOCK_OUT_TOURNAMENT_SIZE.LARGE:
      return 16;
  }
};

export const setCookie = (favoriteTeams: string[], tournamentDate: Date) => {
  tournamentDate.setDate(tournamentDate.getDate() + 1); // Expires 1 day after the tournament
  document.cookie =
    "tamil-football-favorites=" + JSON.stringify(favoriteTeams) + "; expires= " + tournamentDate.toUTCString();
};

export const getFavoriteTeamsFromCookies = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; tamil-football-favorites=`);
  if (parts.length === 2) return JSON.parse(parts.pop()?.split(";").shift() ?? "[]");
};

export const generateGroupsWithTeams = (groups: Array<GroupType>, teams: Array<any>) => {
  const newGroups: any = [];
  groups.forEach((group) => {
    const teamsForGroup = teams.filter((team) => team.group.id === group.id);

    newGroups.push({
      ...group,
      teams: teamsForGroup,
    });
  });
  return newGroups;
};

export const generateTeamsWithGroup = (teams: Array<any>, groups: Array<GroupType>) => {
  const newTeams: any = [];
  teams.forEach((team) => {
    const groupForTeam = groups.find((group) => group.id === team.group.id);
    newTeams.push({
      ...team,
      group: groupForTeam,
    });
  });
  return newTeams;
};

export const getRelativePath = (path: string) => {
  return "/" + path.split("/")[1];
};

export const getLogo = (team: TeamType) => {
  if (!team) return <img src={ballLogo} style={{ visibility: "hidden" }} />;

  if (team.club?.logo) {
    return <img src={team.club?.logo} />;
  } else {
    return <img src={ballLogo} />;
  }
};
