import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationType } from "../../types/types";

interface LocationState {
  locations: Array<LocationType>;
  editingLocation?: LocationType;
}

// Define the initial state using that type
const initialState: LocationState = {
  locations: [],
  editingLocation: undefined,
};

export const LocationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<Array<LocationType>>) => {
      state.locations = action.payload.sort((a, b) => {
        return a.location.localeCompare(b.location);
      });
    },
    createNewLocation: (state, action: PayloadAction<LocationType>) => {
      state.locations = [...state.locations, action.payload].sort((a, b) => {
        return a.location.localeCompare(b.location);
      });
    },
    updateLocation: (state, action: PayloadAction<LocationType>) => {
      state.locations = state.locations.map((location) => {
        if (location.id === action.payload.id) {
          return { ...action.payload };
        } else {
          return location;
        }
      });
    },
    removeLocation: (state, action: PayloadAction<string>) => {
      state.locations = state.locations.filter((location) => location.id !== action.payload);
    },

    setEditingLocation: (state, action: PayloadAction<LocationType | undefined>) => {
      state.editingLocation = action.payload;
    },
  },
});

export const { setLocations, createNewLocation, removeLocation, updateLocation, setEditingLocation } =
  LocationsSlice.actions;

export default LocationsSlice.reducer;
